import axios from 'axios'

const res = document.cookie.match(new RegExp('(^| )token=([^;]+)'))
const token = res && res[2]

export default axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://nucleus.dallinosmun.com'
      : 'http://local.dallinosmun.com:3001',
  withCredentials: true,
  headers: { 'content-type': 'application/json', token }
})
