import React, { useState } from 'react'
import styled from 'styled-components'

export default styled.div`
  height: 100vh;
  width: 100vw;
  background: repeating-linear-gradient(
    ${props => props.angle}deg,
    ${props => props.color1},
    ${props => props.color1} ${props => props.width}px,
    ${props => props.color2} ${props => props.width}px,
    ${props => props.color2} ${props => props.width * 2}px
  );
  display: flex;
  align-items: center;
  justify-content: center;
`

export const useKnobs = (defaults = {}) => {
  const [color1, setColor1] = useState(defaults.color1 || 'lightpink')
  const [color2, setColor2] = useState(defaults.color2 || 'salmon')
  const [width, setWidth] = useState(defaults.width || 150)
  const [angle, setAngle] = useState(defaults.angle || 45)
  const props = { color1, color2, width, angle }
  const Debug = (
    <div>
      <input
        type="color"
        value={color1}
        onChange={e => setColor1(e.target.value)}
      />
      <input
        type="color"
        value={color2}
        onChange={e => setColor2(e.target.value)}
      />
      <input
        type="number"
        value={width}
        onChange={e => setWidth(e.target.value)}
      />
      <input
        type="number"
        value={angle}
        onChange={e => setAngle(e.target.value)}
      />
    </div>
  )
  return [props, Debug]
}
