import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import App from './app'
import ErrorBoundary from './error'

ReactDOM.createRoot(document.getElementById('root')).render(
  <ErrorBoundary>
    <Suspense fallback={<div>loading...</div>}>
      <App />
    </Suspense>
  </ErrorBoundary>
)
