import { Router } from '@reach/router'
import React from 'react'
import { unstable_createResource as createResource } from 'react-cache'

import Header from './components/header'
import * as errors from './error'
import axios from './lib/axios'
import Login from './login'
import NotFound from './notfound'
import Unauthorized from './unauthorized'

const currentUser = createResource(() =>
  axios
    .get('/auth/me')
    .catch(err => {
      throw new errors.Unauthenticated()
    })
    .then(resp => {
      if (resp.data.role !== 'admin') throw new errors.Unauthorized()
      return resp.data
    })
)

export default function App() {
  return (
    <Router>
      <Home path="/" />
      <Login path="/login" />
      <Unauthorized path="/unauthorized" />
      <NotFound default />
    </Router>
  )
}

function Home() {
  const user = currentUser.read()
  return (
    <div>
      <Header user={user} />
      <div>APP GOES HERE</div>
    </div>
  )
}
