import React, { useCallback, useState } from 'react'
import { Mug } from 'react-kawaii'
import styled from 'styled-components'

import Box from './components/box'
import Stripes from './components/stripes'
import axios from './lib/axios'

function useInput(defaultValue) {
  const [value, setValue] = useState(defaultValue)
  return { value, onChange: e => setValue(e.target.value) }
}

const H1 = styled.h1`
  margin: 0;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Field = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`

const Input = styled.input`
  padding: 15px;
  border: 1px solid #aaa;
`

const P = styled.p`
  color: red;
`

export default function Login() {
  const username = useInput('')
  const password = useInput('')
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState('')
  const handleClick = useCallback(
    () => {
      setDisabled(true)
      setError('')
      axios
        .post('/auth/login', {
          username: username.value,
          password: password.value
        })
        .then(() => (window.location = '/'))
        .catch(err => {
          console.error(err)
          setError(err.toString())
          setDisabled(false)
        })
    },
    [username.value, password.value]
  )
  return (
    <Stripes width={200} angle={135} color1="#97c583" color2="#3BAA46">
      <Box padWidth={170}>
        <H1>
          <span>Sign In</span>
          <Mug size={70} mood="happy" color="#30C31A" />
        </H1>
        <Field>
          <label>Username</label>
          <Input type="text" {...username} autoFocus />
        </Field>
        <Field>
          <label>Password</label>
          <Input type="password" {...password} />
        </Field>
        {error && <P>{error}</P>}
        <button disabled={disabled} onClick={handleClick}>
          Log In
        </button>
      </Box>
    </Stripes>
  )
}
