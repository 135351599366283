import React from 'react'
import { Ghost } from 'react-kawaii'
import styled from 'styled-components'

import Box from './components/box'
import Stripes from './components/stripes'

const H1 = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 200px;
  margin: 0;
`

export default function NotFound() {
  return (
    <Stripes width={200} angle={110} color1="#ced10d" color2="#dee029">
      <Box opacity={0.7}>
        <H1>
          4<Ghost size={200} mood="sad" color="#ddea3e" />4
        </H1>
        <p>The page you're looking for just isn't here!</p>
      </Box>
    </Stripes>
  )
}
