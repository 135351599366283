import React from 'react'
import { SpeechBubble } from 'react-kawaii'

import Box from './components/box'
import Stripes from './components/stripes'

export default class ErrorBoundary extends React.Component {
  state = { error: false }

  componentDidCatch(err) {
    if (err instanceof Unauthenticated) return (window.location = '/login')
    if (err instanceof Unauthorized) return (window.location = '/unauthorized')
    console.error(err)
    this.setState({ error: true })
  }

  render() {
    if (!this.state.error) return this.props.children
    return (
      <Stripes width={200} angle={30} color1="#b60606" color2="#c56767">
        <Box>
          <SpeechBubble size={200} mood="shocked" color="#ff8080" />
          <h1>Oh No! An Error Occurred!!</h1>
          <p>Check your console for more details.</p>
        </Box>
      </Stripes>
    )
  }
}

export class Unauthenticated extends Error {}

export class Unauthorized extends Error {}
