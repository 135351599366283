import React from 'react'
import styled from 'styled-components'

import axios from '../lib/axios'

const logout = async () => {
  await axios.post('/auth/logout')
  window.location.reload()
}

const Wrapper = styled.div`
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.5);
  padding: 15px 0;
  background: #b9c6f3;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Profile = styled.div`
  position: absolute;
  right: 20px;
  > :first-child {
    padding-right: 10px;
  }
`

export default function App({ user }) {
  return (
    <Wrapper>
      <div>DallinOsmun.com</div>
      <Profile>
        <span>{user.name}</span>
        <button onClick={logout}>Sign Out</button>
      </Profile>
    </Wrapper>
  )
}
