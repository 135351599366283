import React from 'react'
import { Browser } from 'react-kawaii'

import Box from './components/box'
import Stripes from './components/stripes'

export default function Unauthorized() {
  return (
    <Stripes width={200} angle={60} color1="#32C4CB" color2="#36BAAA">
      <Box opacity={0.7}>
        <Browser size={200} mood="sad" color="#61DDBC" />
        <h1>Unauthorized!!</h1>
        <p>You are not allowed to view this page.</p>
      </Box>
    </Stripes>
  )
}
